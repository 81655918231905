import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto`}</strong>{` -- useful cryptographic plumbing`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto <command> [arguments]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`The `}<strong parentName="p">{`step crypto`}</strong>{` command group provides a selection of useful cryptographic
primitives that balances completeness and safety (cryptographic strength, ease
of use, and misuse prevention). Subcommands include flags and arguments to
select algorithms and fine-tune behaviors, but we've selected safe defaults for
you wherever possible.`}</p>
    <p>{`Insecure or subtle cryptographic primitives and options are gated with flags to
prevent accidental misuse. Such primitives and options will not work unless you
pass the corresponding flags to indicate that you understand the risks
(`}<strong parentName="p">{`--insecure`}</strong>{` and `}<strong parentName="p">{`--subtle`}</strong>{`, respectively). Our rationale for these
decisions is usually documented in the `}<strong parentName="p">{`SECURITY CONSIDERATIONS`}</strong>{` section of
the help for each subcommand.`}</p>
    <h2>{`Security considerations`}</h2>
    <p>{`The strength of cryptographic mechanisms depends on the strength of all links
in the security chain. This includes the quality and strength of algorithms,
random number generation, distribution mechanisms, etc. It also includes
protection against hostile observation and tampering as well as the security of
the overall system including the operating system and personnel, etc. Where
possible, we've selected secure defaults. Whenever a subtle or insecure
cryptographic operation is attempted affirmative confirmation via prompt or
command line flag is required, indicating that you understand and accept the
risks. That said, many of these factors are beyond the scope of this tool.`}</p>
    <p><strong parentName="p">{`Key Length`}</strong></p>
    <p>{`This tool enforces a minimum key size of `}<strong parentName="p">{`256 bits for symmetric keys`}</strong>{`, which is
generally considered quantum-safe and accepted as sufficient for the
foreseeable future.`}</p>
    <p>{`This tool enforces the NIST recommended minimum key size of `}<strong parentName="p">{`2048 bits for RSA`}</strong>{`
keys, which RSA claims is equivalent in strength to 112 bit symmetric keys and
is likely to be sufficient until 2030. An RSA key length of at least 3072 bits,
which RSA claims is equivalent to 128 bit symmetric keys, should be used if
security is required beyond 2030.`}</p>
    <p>{`Elliptic curve cryptography is generally believed to be secure with shorter
keys than RSA requires. NIST guidelines state that ECC keys should be twice the
length of the equivalent strength symmetric key. The rough equivalencies for
the elliptic curves supported by this tool are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`key type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`curve`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`RSA equivalent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`symmetric key equivalent`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~3000 bits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~128 bits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-384`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~4096 bits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~192 bits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-521`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~15000 bits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~256 bits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OKP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ed25519`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~3000 bits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`~140 bits`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Elliptic curve cryptography has the additional advantages of much smaller key
sizes for equivalent security levels, and much faster cryptographic operations
compared to RSA. The strength of these keys is generally considered sufficient
for the predictable and foreseeable future.`}</p>
    <p>{`Note that for cryptographic protocols that have perfect forward secrecy and
only use asymmetric keys for symmetric key negotiation your system will remain
secure against future threats as long as the keys are large enough that they
cannot be cracked today. In other words, sizing your keys to protect against
potential future threats is largely irrelevant.`}</p>
    <p><strong parentName="p">{`Key Use`}</strong></p>
    <p>{`In general you should not use an asymmetric keypair for both signing and
encryption. Using a single key for both operations can introduce attack vectors
that would not otherwise exist. Attacks aside, signing keys and encryption
keys generally have different life cycles. Signing keys are generally destroyed
once they're no longer useful for singing new data. Encryption keys, on the
other hand, must be retained as long as data exists that was encrypted for the
key. So using a signing key for encryption may force you to retain a signing
key for longer than it's needed, leaving it susceptible to misuse.`}</p>
    <p>{`Raw public or private keys don't have any associated data, therefore this
tool cannot enforce key use on raw keys and this responsibility is up to
you. For keys in an "envelope" the envelope typically includes key use
restrictions (e.g., the "use" parameter in JWKs and the "Key Usage"
attribute of X.509 certificates). This tool generally requires key use to be
specified when creating an enveloped key, and enforces key use restrictions
when an enveloped key is being used.`}</p>
    <p><strong parentName="p">{`Safe Curves`}</strong></p>
    <p>{`There is some concern that certain standard elliptic curves are very hard to
implement correctly. These concerns are not purely theoretical. Implementation
issues have been uncovered and real attacks have been demonstrated.`}</p>
    <p>{`While we take these concerns seriously, these curves are widely used in
practice, largely because they are perceived to be stronger than RSA and have
been implemented in more places than the "safe curves". Therefore, `}<strong parentName="p">{`we've
opted not to gate non-safe curves`}</strong>{`. We've further elected to make `}<strong parentName="p">{`P-256`}</strong>{`
the default curve for EC keys.`}</p>
    <p>{`Still, it is important to be aware of the security risks associated with their
risk. You should consider using "safe curves" if possible. We may change our
mind as support for safe curves improves.`}</p>
    <p>{`Safe and non-safe curves implemented by this tool are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`key type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`curve`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`safe`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-384`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-521`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OKP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ed25519`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For more information see `}<a parentName="p" {...{
        "href": "https://safecurves.cr.yp.to/"
      }}>{`https://safecurves.cr.yp.to/`}</a></p>
    <p><strong parentName="p">{`Quantum Safety`}</strong></p>
    <p>{`Quantum-safe cryptography refers to keys and algorithms that are secure against
an attack by a quantum computer. As of 2018 most public key algorithms are not
quantum safe. In particular, `}<strong parentName="p">{`none of the public key algorithms implemented by
this tool are quantum safe`}</strong>{`. However, no quantum computer exists that is
powerful enough to break current algorithms. Using cryptographic protocols with
forward secrecy is the best way to protect against future quantum attacks.`}</p>
    <p><strong parentName="p">{`Forward Secrecy`}</strong></p>
    <p>{`A cryptosystem or protocol has forward secrecy (or perfect forward secrecy) if,
for each session or interaction, a random key is generated such that an
attacker with access to all private keys would still not know the generated
key. This can be accomplished using Diffie-Hellman key exchange, for instance.`}</p>
    <p>{`Forward secrecy can protect against an attacker who stores intercepted
communication and waits for your private key to be compromised, at which point
they could decrypt the stored communication. It also offers good protection
against quantum attacks since symmetric key cryptosystems like AES are already
considered quantum resistant with sufficiently large key sizes. The current
best quantum attack against symmetric key systems requires work proportional to
the square of the size of the key space. In other words, a symmetric key is
half as strong against a quantum attack vs. a conventional attack, so your key
needs to be twice as long for equivalent quantum-safe security. A 256 bit
symmetric key in the context of a quantum attack is equivalent in strength to a
128 bit key in the context of a conventional attack.`}</p>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "change-pass/"
              }}>{`change-pass`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`change password of an encrypted private key (PEM or JWK format)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "keypair/"
              }}>{`keypair`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate a public / private keypair in PEM format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "jwk/"
              }}>{`jwk`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create JWKs (JSON Web Keys) and manage JWK Key Sets`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "jwt/"
              }}>{`jwt`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sign and verify data using JSON Web Tokens (JWT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "jwe/"
              }}>{`jwe`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`encrypt and decrypt data and keys using JSON Web Encryption (JWE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "jws/"
              }}>{`jws`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sign and verify data using JSON Web Signature (JWS)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "jose/"
              }}>{`jose`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`collection of JOSE utilities`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "hash/"
              }}>{`hash`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate and check hashes of files and directories`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "kdf/"
              }}>{`kdf`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`key derivation functions for password hashing and verification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "key/"
              }}>{`key`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`manage keys`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "nacl/"
              }}>{`nacl`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`easy-to-use high-speed tools for encryption and signing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "otp/"
              }}>{`otp`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate and verify one-time passwords`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "winpe/"
              }}>{`winpe`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`extract certificates and verify Windows Portable Executable files`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      